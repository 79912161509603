

















































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Settings16 from '@carbon/icons-vue/es/settings/16'
import Add16 from '@carbon/icons-vue/es/add/16'
import ArrowUp16 from '@carbon/icons-vue/es/arrow--up/16'
import ArrowDown16 from '@carbon/icons-vue/es/arrow--down/16'
import axios from 'axios'
import { mask } from 'vue-the-mask'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import PermissionsMixin from '@/mixins/permissionsMixin'
import TopicsComboBox from '@/partials/forms/TopicsComboBox.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import FileUploader from '@/partials/forms/FileUploader.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  directives: { mask },
  components: {
    Settings16,
    TrashCan16,
    Add16,
    ArrowUp16,
    ArrowDown16,
    TopicsComboBox,
    ValidationObserver,
    ValidationProvider,
    NotifyOnFailed,
    FileUploader,
    PasswordConfirmField
  }
})
export default class QuestionsCreate extends Mixins(PermissionsMixin, FlashMessageMixin, ValidatorConfigMixin) {
  options = [
    {
      option: '',
      key: 1,
      correct: false
    },
    {
      option: '',
      key: 2,
      correct: false
    },
    {
      option: '',
      key: 3,
      correct: true
    },
    {
      option: '',
      key: 4,
      correct: false
    }
  ]

  globalKey = 5

  optionsType = 'radio'

  form: Record<string, any> = {
    code: '',
    type: 'radio',
    level: '50',
    points: 1,
    time: 60,
    body: '',
    justification: '',
    topics: [],
    file: '',
    justificationFile: '',
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  done = true

  time = {
    minutes: 0,
    seconds: 0
  }

  submit () {
    this.done = false
    const formOptions = this.options.map((option, index) => {
      return { option: option.option, order: index, type: this.optionsType, correct: option.correct }
    })

    const textOptions = [
      {
        type: 'text',
        order: 1,
        option: 'text',
        correct: false
      }
    ]

    const submitForm = {
      ...this.form,
      options: this.form.type === 'radio' ? formOptions : textOptions,
      time: this.time.minutes * 60 + this.time.seconds
    }

    axios.post('question', submitForm)
      .then(() => {
        this.$router.push({ name: 'QuestionsDrafts' }, () => {
          const flashMessage = {
            message: 'Questão de rascunho enviada com sucesso.',
            isSuccess: true
          }
          this.setFlashMessage(flashMessage)
        })
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  created () {
    if (process.env.NODE_ENV === 'development') {
      this.form = {
        code: 'Q123',
        type: 'radio',
        level: '50',
        points: 4,
        time: 60,
        body: 'Donec finibus est ut arcu varius tempor. Vivamus efficitur et diam vitae vulputate. Mauris tempor nibh in odio mollis facilisis.',
        justification: 'Justification',
        topics: [],
        file: '',
        justificationFile: '',
        confirm: '',
        errors: {
          confirm: ''
        }
      }
    }
  }

  makeOptionsIncorrect () {
    this.options = this.options.map(option => {
      option.correct = false
      return option
    })
  }

  removeChoice (index: number) {
    if (this.options.length > 2) {
      if (this.options[index]?.correct) {
        this.options[0].correct = true
      }
      this.options.splice(index, 1)
    }
  }

  addChoice (index: number) {
    const choice = { option: '', key: this.globalKey++, correct: false }
    this.options.splice(index, 0, choice)
  }

  moveChoice (index: number, direction: string) {
    let targetIndex: number

    if (direction === 'up') {
      targetIndex = index - 1
    } else {
      targetIndex = index + 1
    }

    const swappingChoice = this.options[targetIndex]

    this.options.splice(targetIndex, 1)
    this.options.splice(index, 0, swappingChoice)
  }
}

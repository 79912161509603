


















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FileUploader extends Vue {
  @Prop({ required: true }) acceptedFormats!: Array<string>
  @Prop() label!: string
  @Prop() helperText!: string
  filesizeMB = 0
  uploader: UploaderArray = []
  base64file = ''

  async onUpload ($e: any) {
    const filename = $e.target.files[0].name
    const fileExt = filename.split(/(?=\.)/g).slice(-1).pop().toLowerCase()
    this.base64file = await this.toBase64($e.target.files[0]) as string

    this.filesizeMB = new Blob([this.base64file]).size / 10 ** 6
    const isAcceptedFormat = this.acceptedFormats.includes(fileExt)

    if (isAcceptedFormat && this.filesizeMB < 50) {
      this.uploader[0].state = 'complete'
      this.$emit('success', this.base64file)
    } else {
      this.uploader[0].state = ''
      this.uploader[0].invalidMessage = isAcceptedFormat ? 'Arquivo excede 50 MB' : 'Formato não suportado'
      this.uploader[0].invalidMessageTitle = 'Erro'
    }
  }

  toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  });
}
